import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Multiselect from "multiselect-react-dropdown";
import { APIX } from "../config/config";
import AlertDialog from "../components/Alert/alert";

const About = () => {
	const navigate = useNavigate();
	const [alt, setAlt] = useState(false)
	const [alt2, setAlt2] = useState(false)
	const [name, setName] = useState("");
	const [phone, setPhone] = useState("");
	const [msg, setMsg] = useState("");
	const [option1, setOption1] = useState([]);
   
   
	const sentDataCreateImg = async () => {

		console.log("name",name);
		console.log("phone",phone);
		console.log("msg",msg);
		const body = {
			name: name,
			phone: phone,
			msg: msg,
		  };
		  try {
			await axios
			  .post(APIX.chat, body)
			  .then((status) => {
				
				setAlt(false)
				navigate("/");
			  })
			  .catch((error) => {
				setAlt(true)
				console.log("apiยิงไม่ได้");
			  });
		  } catch {
			setAlt(true)
			console.log("ยังสร้างรหัสไม่ได้");
		  }
	};
  
  
  
	useEffect(() => {
	  fetchOption();
	}, []);
  
	const fetchOption = () => {
	  let config = {
		method: "get",
		maxBodyLength: Infinity,
		url: APIX.tag_getAll,
		headers: {},
	  };
  
	  axios
		.request(config)
		.then((response) => {
		  console.log(JSON.stringify(response.data));
		  setOption1(response.data);
		})
		.catch((error) => {
		  console.log(error);
		});
	};
	

  
	const state = {
	  options: option1,
	};
  
	return (
	  <div
		style={{
		  display: "flex",
		  justifyContent: "Center",
		  alignItems: "Center",
		  marginTop: "3rem",
		}}
	  >
		<div>
		<AlertDialog open={alt} setOpen={setAlt} mode={"error"} msg={"กรุณากรอกเงื่อนไขให้ครบถ้วน"}></AlertDialog>
      <AlertDialog open={alt2} setOpen={setAlt2} mode={"error"} msg={"ชื่อคลิปหรือรายละเอียดนี้มีอยู่เเล้ว"}></AlertDialog>
		  <div>
			<h3 style={{ textAlign: "center" }}>
			  ติดต่อ Addmin
			</h3>
			<div className="mb-3" style={{ marginTop: "2rem" }}></div>
			<div className="mb-3">
			  <label>* ชื่อ-นามสกุล</label>
			  <input
				value={name ? name : ""}
				onChange={(e) => {
				  setName(e.target.value);
				}}
				type="name"
				className="form-control"
				placeholder="กรอกชื่อ-นามสกุล"
			  />
			</div>
  
			<div className="mb-3">
			  <label>* เบอร์โทรติดต่อ</label>
			  <input
				value={phone ? phone : ""}
				onChange={(e) => {
				  setPhone(e.target.value);
				}}
				type="name"
				className="form-control"
				placeholder="กรอกเบอร์โทร"
			  />
			</div>

			<div className="mb-3">
			  <label>* ข้อความ</label>
			  <input
				value={msg ? msg : ""}
				onChange={(e) => {
				  setMsg(e.target.value);
				}}
				type="name"
				className="form-control"
				placeholder="กรอกข้อความ"
			  />
			  {/* </textarea> */}
			</div>
			
			<div className="d-grid" style={{ marginTop: "1.5rem" }}>
			  <div className="d-flex align-items-start  mb-3 ">
				<div style={{ margin: "auto" }}>
				  <a href="/">
					<button
					  type="submit"
					  className="btn btn-primary"
					  style={{
						textAlign: "center",
						padding: "0.7rem",
						marginRight: "2rem",
					  }}
					>
					  ยกเลิก
					</button>
				  </a>
				  <button
					type="submit"
					className="btn btn-primary"
					style={{ textAlign: "center", padding: "0.7rem" }}
					onClick={() => {
					  sentDataCreateImg();
					}}
				  >
					ตกลง
				  </button>
				</div>
			  </div>
			</div>
		  </div>
		</div>
	  </div>
	);	

};
export default About;
