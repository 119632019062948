import React, { useState, useEffect } from 'react';
import 'react-slideshow-image/dist/styles.css';
import './index.css'
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import PicBaby from './baby-girl.png'
import {
Nav,
NavLink,
Bars,
NavMenu,
NavBtn,
NavBtnLink,
} from './NavbarElements';


const Navbar = ({token, setToken}) => {

	const btnStyle = {
		backgroundColor: '#12168B',
		border:'#12168B'
	  };


	const [show, setShow] = useState(false);

  	const handleClose = () => setShow(false);
  	const handleShow = () => setShow(true);

	const logOut = () => {
		sessionStorage.setItem("Token","")
		sessionStorage.setItem("adminid","")
		setToken("")
	  };

return (
	<>
	<Nav className='shadow ' style={{padding:'0.1rem calc((86vw - 1000px) / 2)'}}>
		<a href="/" style={{marginTop:'0.5rem'}}>
			<button style={{borderRadius:'6rem'}}>
				<img src={PicBaby} alt="React Image" height={40} width={40} style={{borderRadius:'6rem'}}/>
			</button>
		</a>
		
		<Bars />
		<NavMenu>
		<NavLink to='/events' activeStyle  className={"font-size-N zoom"}>
		สื่อนวัตกรรมภายในพื้นที่อำเภอชาวขุนยวน
	
		</NavLink>
		<NavLink to='/annual' activeStyle className={"font-size-N zoom"}>

			สื่อนวัตกรรมที่น่าสนใจนอกพื้นที่
		
	
		</NavLink>
		<NavLink onClick={handleShow} activeStyle className={"font-size-N zoom"}>
			นโยบาย
			
		</NavLink>


		</NavMenu>
		<NavBtn>
			<div>
				{token ? 
				<div><NavBtnLink to='/sign-in' onClick={() => {logOut()}}>ออกจากระบบ</NavBtnLink></div> 
				: <div className='zoom'><NavBtnLink to='/sign-in' className={"font-size-H "}>เข้าสู่ระบบ</NavBtnLink></div>}
				
			</div>
		
		</NavBtn>
	</Nav>
	<Modal show={show} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>นโยบาย</Modal.Title>
        </Modal.Header>
        <Modal.Body>1.เพื่อเป็นช่องทางการเเลกเปลี่ยนความรู้นวัตกรรมดูเเล/พัฒนาเด็กปฐมวัยในอำเภอขุนยวน</Modal.Body>
		<Modal.Body>2.เพื่อเก็บรวบรวมข้อมูล/นวัตกรรมรูปเเบบการดูเเล/พัฒนาเด็กปฐมวัยในอำเภอขุนยวน</Modal.Body>
		<Modal.Body>3.เป็นชุมชนคนปฐมวัยได้พัฒนากระบวนการดูเเลเด็กในอำเภอขุนยวน</Modal.Body>
        <Modal.Footer>
		  <Button variant="secondary" onClick={handleClose} style={btnStyle}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>
	</>
);
};

export default Navbar;
