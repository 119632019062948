import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { Alert } from "@mui/material";
import Stack from "@mui/material/Stack";
import { APIX } from "../config/config";
import AlertDialog from "../components/Alert/alert";

const SignUp = () => {
  const navigate = useNavigate();
  const [alt, setAlt] = useState(false)
  const [alt2, setAlt2] = useState(false)
  // const [selectionId, setSelectionId] = useState("");
  const [selectionName, setSelectionName] = useState("");
  const [selectionSur, setSelectionSur] = useState("");
  const [selectionMail, setSelectionMail] = useState("");
  const [selectionPass, setSelectionPass] = useState("");
  const [selectionCon, setSelectionCon] = useState("");
  const [selectionCheck, setSelectionCheck] = useState(false);

  const btnStyle = {
    backgroundColor: '#12168B',
    border:'#12168B'
  };

  function toggleButton() {
    var checkbox = document.getElementById("myCheckbox");
    var button = document.getElementById("myButton");
    
    // Set the disabled attribute based on the checkbox's checked state
    button.disabled = !checkbox.checked;
  }

  function checkInputs() {
    var email = document.getElementById("email").value;
    var password = document.getElementById("password").value;
    var fname = document.getElementById("fname").value;
    var lname = document.getElementById("lname").value;
    var confirmPass = document.getElementById("confirmPass").value;
    var submitButton = document.getElementById("myButton");
    var checkbox = document.getElementById("myCheckbox");
    
    // Check if email and password have values
    if (email && password && fname && lname && confirmPass && checkbox.checked) {
      submitButton.disabled = false; // Enable the submit button
    } else {
      submitButton.disabled = true; // Disable the submit button
    }
  }

  const sentDataCreatId = async () => {
    if (selectionPass === selectionCon && selectionCheck === true) {
      const body = {
        // id:selectionId,
        name: selectionName,
        surname: selectionSur,
        email: selectionMail,
        password: selectionPass,
      };
      try {
        await axios
          .post(APIX.admin, body)
          .then((status) => {
            console.log("สร้างได้เเล้ว", status);
            setAlt(false)
            navigate("/sign-in");
            // setSelectionId("")
            // setSelectionName("")
            // setSelectionSur("")
            // setSelectionMail("")
            // setSelectionPass("")
            // setSelectionCheck("")
          })
          .catch((error) => {
            setAlt2(true)
            setAlt(false)
            console.log("apiยิงไม่ได้");
          });
      } catch {
        setAlt(true)
        console.log("ยังสร้างรหัสไม่ได้");
      }
    } else {
      console.log("ยืนยันรหัสผ่านไม่ถูกต้อง");
      // <Stack sx={{ width: "100%" }} spacing={2}>
      //   <Alert severity="error">This is an error alert — check it out!</Alert>
      // </Stack>;
      setAlt(true)
    }
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "Center",
        alignItems: "Center",
        marginTop: "3rem",
      }}
      className="bg-text rounded"
    >
      <div>
      <AlertDialog open={alt} setOpen={setAlt} mode={"error"} msg={"กรุณากรอกเงื่อนไขให้ครบถ้วน"}></AlertDialog>
      <AlertDialog open={alt2} setOpen={setAlt2} mode={"error"} msg={"ชื่อผู้ใช้นี้มีอยู่เเล้ว"}></AlertDialog>
        <div>
          <p></p>
          <h3 style={{ textAlign: "center" }}>สมัครสมาชิก</h3>
          {/* <div className="mb-3">
						<label>* ID</label>
							<input
								value={selectionId ? selectionId:'' }
								onChange={(e) => {
								   setSelectionId(e.target.value);
								 }}
								type="name"
								className="form-control"
								placeholder="ID"
							/>
					</div> */}
          {/* //////// */}
          <div className="mb-3">
            <label>* ชื่อ</label>
            <input
              value={selectionName ? selectionName : ""}
              onChange={(e) => {
                setSelectionName(e.target.value);
                checkInputs()
              }}
              type="name"
              className="form-control"
              placeholder="กรอกชื่อ"
              id='fname'
            />
          </div>
          <div className="mb-3">
            <label>* นามสกุล</label>
            <input
              value={selectionSur ? selectionSur : ""}
              onChange={(e) => {
                setSelectionSur(e.target.value);
                checkInputs()
              }}
              type="name"
              className="form-control"
              placeholder="กรอกนามสกุล"
              id='lname'
            />
          </div>
          <div className="mb-3">
            <label>* อีเมล</label>
            <input
              value={selectionMail ? selectionMail : ""}
              onChange={(e) => {
                setSelectionMail(e.target.value);
                checkInputs()
              }}
              type="email"
              className="form-control"
              placeholder="กรอกอีเมล"
              id='email'
            />
          </div>
          <div className="mb-3">
            <label>* รหัสผ่าน</label>
            <input
              value={selectionPass ? selectionPass : ""}
              onChange={(e) => {
                setSelectionPass(e.target.value);
                checkInputs()
              }}
              type="password"
              className="form-control"
              placeholder="กรอกรหัสผ่าน"
              id='password'
            />
          </div>
          <div className="mb-3">
            <label>* ยืนยันรหัสผ่าน</label>
            <input
              value={selectionCon ? selectionCon : ""}
              onChange={(e) => {
                setSelectionCon(e.target.value);
                checkInputs()
              }}
              type="password"
              className="form-control"
              placeholder="กรอกรหัสผ่าน"
              id='confirmPass'
            />
          </div>

          <div style={{ marginTop: "0.25rem" }}>
            <label>
              <input
                // value={selectionCheck ? selectionCheck:false }
                onChange={() => {
                  setSelectionCheck(!selectionCheck);
                  // toggleButton()
                  checkInputs()
                }}
                style={{ marginRight: "0.25rem" }}
                type="checkbox"
                id="myCheckbox"
              />
              ยอมรับข้อตกลง เงื่อนไขและนโยบายความเป็นส่วนตัว
            </label>
          </div>
          <div className="d-grid" style={{ marginTop: "1rem" }}>
            {/* <a href="/" > */}
            <button
              // style={{ textAlign: "center" }}
              style={btnStyle}
              type="submit"
              className="btn btn-primary"
              onClick={() => {
                sentDataCreatId();
              }}
              id="myButton"
            >
              ลงทะเบียน
            </button>
            <p/>
            {/* </a> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignUp;
