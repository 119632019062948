import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Multiselect from "multiselect-react-dropdown";
import { APIX } from "../config/config";
import AlertDialog from "../components/Alert/alert";

const Teams = () => {
  const navigate = useNavigate();
  const [alt, setAlt] = useState(false)
  const [alt2, setAlt2] = useState(false)
  const [selectionName, setSelectionName] = useState("");
  const [selectionLink, setSelectionLink] = useState("");
  const [selectionDes, setSelectionDes] = useState("");
  const [selected, setSelected] = useState([]);
  // const [selectionAdmin, setSelectionAdmin] = useState(sessionStorage.getItem("adminid"));
  const [selectionType, setSelectionType] = useState("1001");
  const [option1, setOption1] = useState([]);
  const sentDataCreatVideo = async () => {
    console.log("select", selected);
    const list = [];
    selected.map((e) => {
      list.push({ tagId: e.id });
    });
    const body = {
      videoname: selectionName,
      videolink: selectionLink,
      videodesc: selectionDes,
      adminId: sessionStorage.getItem("adminid"),
      typeId: selectionType,
      listDetail: list,
    };
    try {
      await axios
        .post(APIX.video, body)
        .then((status) => {
          console.log("สร้างได้เเล้ว", status);
          setAlt(false)
          navigate("/");
        })
        .catch((error) => {
          setAlt(true)
          console.log("apiยิงไม่ได้");
        });
    } catch {
      setAlt(true)
      console.log("ยังสร้างรหัสไม่ได้");
    }
  };
  useEffect(() => {
    fetchOption();
  }, []);

  const fetchOption = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: APIX.tag_getAll,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setOption1(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  function onSelect(selectedList, selectedItem) {
    setSelected(selectedList);
  }

  function onRemove(selectedList, removedItem) {
    setSelected(selectedList);
  }
  const state = {
    options: option1,
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "Center",
        alignItems: "Center",
        marginTop: "3rem",
      }}
      className="bg-text rounded"
    >
      <div>
      <AlertDialog open={alt} setOpen={setAlt} mode={"error"} msg={"กรุณากรอกเงื่อนไขให้ครบถ้วน"}></AlertDialog>
      <AlertDialog open={alt2} setOpen={setAlt2} mode={"error"} msg={"ชื่อคลิปหรือรายละเอียดนี้มีอยู่เเล้ว"}></AlertDialog>
        <div>
          <p />
          <h3 style={{ textAlign: "center" }}>
            อัปโหลดสื่อนวัตกรรมของชาวขุนยวน
          </h3>
          <div className="mb-3" style={{ marginTop: "2rem" }}></div>
          <div className="mb-3">
            <label>* ชื่อคลิป</label>
            <input
              value={selectionName ? selectionName : ""}
              onChange={(e) => {
                setSelectionName(e.target.value);
              }}
              type="name"
              className="form-control"
              placeholder="กรอกชื่อคลิป"
            />
          </div>
          <div className="mb-3">
            <label>* รายละเอียดคลิป</label>
            <input
              value={selectionDes ? selectionDes : ""}
              onChange={(e) => {
                setSelectionDes(e.target.value);
              }}
              type="detail"
              className="form-control"
              placeholder="กรอกรายละเอียดคลิป"
            />
          </div>
          <div className="mb-3">
            <label>* ประเภทสื่อ</label>
            <select
              className="form-control"
              onChange={(e) => {
                setSelectionType(e.target.value);
              }}
            >
              <option value="1001">สื่อนวัตกรรมของชาวขุนยวน</option>
              <option value="2001">สื่อนวัตกรรมที่น่าสนใจในพื้นที่</option>
            </select>
            {/* <input
								type="name"
								className="form-control"
								placeholder="กรุณาเลือกประเภทสื่อ"
							/> */}
          </div>
          <div className="mb-3">
            <label>* ลิ้งค์</label>
            <input
              value={selectionLink ? selectionLink : ""}
              onChange={(e) => {
                setSelectionLink(e.target.value);
              }}
              type="name"
              className="form-control"
              placeholder="กรอกลิ้งค์"
            />
          </div>

          <div>
            <label>* Tag</label>
            <Multiselect
              options={state.options} // Options to display in the dropdown
              // Preselected value to persist in dropdown
              onSelect={onSelect} // Function will trigger on select event
              onRemove={onRemove} // Function will trigger on remove event
              displayValue="name" // Property name to display in the dropdown options
            />
          </div>
          <div className="d-grid" style={{ marginTop: "1.5rem" }}>
            <div className="d-flex align-items-start  mb-3 ">
              <div style={{ margin: "auto" }}>
                <a href="/">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    style={{
                      textAlign: "center",
                      padding: "0.7rem",
                      marginRight: "2rem",
                      backgroundColor: "#FF3333",
                      border: "none",
                      width: 90,
                    }}
                  >
                    ยกเลิก
                  </button>
                </a>
                {/* <a href="/" > */}
                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{
                    textAlign: "center",
                    padding: "0.7rem",
                    marginRight: "2rem",
                    backgroundColor: "#12168B",
                    border: "none",
                    width: 90,
                  }}
                  onClick={() => {
                    sentDataCreatVideo();
                  }}
                >
                  ตกลง
                </button>
                <a href="/addTag">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    style={{
                      textAlign: "center",
                      padding: "0.7rem",
                      backgroundColor: "#10DF93",
                      border: "none",
                      width: 90,
                    }}
                  >
                    เพิ่ม Tag
                  </button>
                </a>

                {/* </a> */}
              </div>
            </div>
            {/* <a href="/" style={{textAlign:'center'}}>
						<button type="submit" className="btn btn-primary"  >
							ตกลง
						</button>
					</a> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Teams;
