export const path = "https://prathuomwai-khumyaum.com/api1v1";

export const v1 = "/api/v1";

export const APIX = {
    //admin
    signIn: path + v1 + "/admin/login",
    admin: path + v1 + "/admin/",

    //image
    image: path + v1 + "/image/",
    image_getAll: path + v1 + "/image/getAll",
    image_getId: path + v1 + "/image/get/",
    image_create: path + v1 + "/image",

    //pdf
    pdf_getAll: path + v1 + "/pdf/getAll",
    pdf_create: path + v1 + "/pdf",

    //video
    video: path + v1 + "/video/",
    video_getAll: path + v1 + "/video/getAll",
    video_getType: path + v1 + "/video/getByType/",
    video_getId: path + v1 + "/video/get/",

    //tag
    tag: path + v1 + "/tag/",
    tag_getAll: path + v1 + "/tag/getAll",
    tag_getId: path + v1 + "/tag/get/",
    tag_create: path + v1 + "/tag",

    //videoDetail
    vd: path + v1 + "/videodetail/",
    vd_getAll: path + v1 + "/videodetail/getAll",
    vd_getId: path + v1 + "/videodetail/get/",

    //pdf
    pdf: path + v1 + "/pdf/",
    pdf_getAll: path + v1 + "/pdf/getAll",
    pdf_getId: path + v1 + "/pdf/get/",

    //chat
    chat: path + v1 + "/chat/",
    chat_getAll: path + v1 + "/chat/getAll"
}