import React, { useEffect, useState } from "react";
import ReactPlayer from "react-player";
import Chip from "@mui/material/Chip";
import { APIX } from "../config/config";
import { useNavigate } from "react-router-dom";
import "./index.css";
import image1 from "../image/movie.png";
import image2 from "../image/picture.png";
import image3 from "../image/reading.png";

const axios = require("axios");
const Events = ({token}) => {
  const BarStyle = {
    width: "50rem",
    width: "100%",
    background: "#F0F0F0",
    border: "none",
    padding: "0.5rem",
    // marginTop: "2rem",
    marginTop: "3rem",
    alignItems: "center",
    backgroundColor: "#fff",
  };
  const [keyWord, setKeyWord] = useState("");
  const [search, setSearch] = useState(true);
  const [videoData, setVideoData] = useState([]);
  const [tagid, setTagid] = useState("");
  const [tag, setTag] = useState([]);

  const imageStyle = {
    width: "130px", // Adjust the width as needed
    height: "110px", // Adjust the height as needed
  };

  const btnStyle = {
    backgroundColor: "#12168B",
    border: "#12168B",
  };

  const navigate = useNavigate();
  useEffect(() => {
    // Update the document title using the browser API
    if (sessionStorage.getItem("SearchTag1") != "") {
      setTagid(1);
      fetchData();
    }
    if (sessionStorage.getItem("SearchTag2") != "") {
      setTagid(2);
      fetchData();
    }
    if (sessionStorage.getItem("SearchTag3") != "") {
      setTagid(3);
      fetchData();
    }

    fetchData();
  }, [search, tagid]);

  // useEffect(() => {
  //   // Update the document title using the browser API
  //   if(sessionStorage.getItem("SearchTag1")!=''){
  //     setTagid(1)
  //     fetchData();
  //     console.log("ttttt");
  //   }
  //   // fetchData();
  // }, []);

  useEffect(() => {
    fetchDataTag();
  }, []);
  const fetchData = () => {
    console.log(tagid);
    let data = JSON.stringify({
      videoname: keyWord,
      tagid: tagid === "" ? undefined : tagid,
    });
    let config = {
      method: "post",
      maxBodyLength: Infinity,
      url: APIX.video_getType + "1001",
      headers: {
        "Content-Type": "application/json",
      },
      data: data,
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setVideoData(response.data);
        sessionStorage.setItem("SearchTag1", "");
        sessionStorage.setItem("SearchTag2", "");
        sessionStorage.setItem("SearchTag3", "");
      })
      .catch((error) => {
        console.log(error);
      });
  };
  const deleteApi = (arr)=>{
    let config = {
      method: 'delete',
      maxBodyLength: Infinity,
      url: 'http://localhost:7070/api/v1/video/'+arr.videoid,
      headers: { }
    };
    
    axios.request(config)
    .then((response) => {
      if(response.status==200){
        const del = videoData.filter((m)=>m.videoid !== arr.videoid)
        setVideoData(del)
      } 
    })
    .catch((error) => {
      console.log(error);
    });
  }
  

  const fetchDataTag = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: APIX.tag_getAll,
      headers: {
        "Content-Type": "application/json",
      },
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setTag(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    
    <div
      style={{
        display: "flex",
        justifyContent: "Center",
        alignItems: "Center",
        marginTop: "2rem",
      }}
    >
      <div style={{ textAlign: "center" }}>
        <p/>
        <h3 className="text-eff"><span>สื่อนวัตกรรมภายในพื้นที่อำเภอชาวขุนยวน</span></h3>
        <div class="container text-center">
          <div class=" row gx-5 flex flex-col md:flex-row items-center mt-6 mr-6 w-full">
            <div className="col-sm-6 col-md-9 flex flex-col md:flex-row items-center mt-6 mr-6 w-full">
              <div class="mb-5 items-center ms-auto w-full">
              <input
              type="search"
              className="searchTextFileCode2"
              style={BarStyle}
              key="search-bar"
              value={keyWord}
              placeholder={"ค้นหาจากชื่อวิดีโอ"}
              onChange={(e) => {
                setKeyWord(e.target.value);
              }}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  console.log("enter");
                  setSearch(!search);
                }
              }}
            />
              </div>
            </div>
            <div class="row align-items-center col-6 col-md-3 flex flex-col md:flex-row items-center mt-6 mr-6 w-full ">
              <button type="button" class="btn btn-dark w-bt" style={btnStyle}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-search"
                  viewBox="0 0 16 18"
                >
                  <path d="M11.742 10.344a6.5 6.5 0 1 0-1.397 1.398h-.001c.03.04.062.078.098.115l3.85 3.85a1 1 0 0 0 1.415-1.414l-3.85-3.85a1.007 1.007 0 0 0-.115-.1zM12 6.5a5.5 5.5 0 1 1-11 0 5.5 5.5 0 0 1 11 0z" />
                </svg>
                {" "}
                ค้นหา
              </button>
            </div>
          </div>
        </div>

        <div class="d-flex bd-highlight">
          <div class="p-2 w-100 bd-highlight" style={{ textAlign: "start" }}>
          <h5>#Tag เเนะนำ</h5>
          </div>
          <div
            class="p-2 flex-shrink-1 bd-highlight"
            style={{ width: "20rem" }}
          >
            <div>{token ? <a href="/team">อัปโหลดคลิป</a> : <></>}</div>
          </div>
        </div>
        <div className="d-flex align-items-start mb-3">
          {/* ตรงนี้เป็น tagที่รันนะ */}
          <div style={{}}>
            {tag.map((button, index) => (
              <button 
              type="button" class="btn "
                key={button.id}
                style={{
                  backgroundColor: tagid === button.id ? "pink" : "initial",
                  marginLeft: "1rem",
                  borderRadius: "1rem",
                  paddingLeft: "1rem",
                  paddingRight: "1rem",
                  paddingTop: "0.3rem",
                  paddingBottom: "0.3rem",
                }}
                onClick={() => {
                  setTagid(button.id);
                  setKeyWord("");
                  console.log("button.id:", button.id);
                }}
              >
                <div class="card text  zoom">
                  <img  src={`data:image/jpeg;base64,${button.path_base64}`} style={imageStyle} class="card-img m-4" />
                  <div class="card-img">
                    <h5 class="card-title">{button.name}</h5>
                  </div>
                </div>
              </button>
            ))}
          </div>
        </div>
        
        <div>
          {videoData.map((video, index) => (
            <div className="d-flex bg-light mb-2" key={index}>
              <ReactPlayer url={video.videolink} width="23rem" height="15rem" />
              <div style={{ marginLeft: "12rem", marginTop: "1rem" }}>
                <p>{video.videoname}</p>
                <p>{video.videodesc}</p>
                <div>
                  {video.videoDetail.map((chip, index) => (
                    <Chip
                      key={index}
                      label={chip.tagname}
                      variant={chip.variant}
                    />
                  ))}
                </div>
                <div>
                  {token ? (
                   <div>
                     <button
                      style={{ marginTop: "0.5rem" }}
                      onClick={() => {
                        navigate("/teamEdit");
                        sessionStorage.setItem(
                          "videotask",
                          JSON.stringify(video)
                        );
                      }}
                    >
                      แก้ไข
                    </button>
                    <button
                      style={{ marginTop: "0.5rem" }}
                      onClick={() => {
                       deleteApi(video)
                      }}
                    >
                      ลบ
                    </button>
                   </div>
                  ) : (
                    <></>
                  )}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      </div>
    
  );
};

export default Events;
