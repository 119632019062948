import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import "../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import axios from "axios";
import { APIX } from "../config/config";
import imgUser from "../image/user (1).png";
import AlertDialog from "../components/Alert/alert";

const SignIn = ({ setToken }) => {
  // function refreshPage() {
  // 	window.location.reload(true);
  //   }
  const navigate = useNavigate();
  const [selectionMail, setSelectionMail] = useState("");
  const [selectionPass, setSelectionPass] = useState("");
  const [alt, setAlt] = useState(false)
  // const [token, setToken] = useState(""); //ส่งไปหน้าเเรกว่ามี token เเล้วก็เปลี่ยนจากเข้าสู่ระบบ เป็นออกจากระบบ

  const imageStyle = {
    width: "70px", // Adjust the width as needed
    height: "70px", // Adjust the height as needed
  };

  const btnStyle = {
    backgroundColor: '#12168B',
    border: '#12168B'
  };

  function checkInputs() {
    var email = document.getElementById("email").value;
    var password = document.getElementById("password").value;
    var submitButton = document.getElementById("submitButton");

    // Check if email and password have values
    if (email && password) {
      submitButton.disabled = false; // Enable the submit button
    } else {
      submitButton.disabled = true; // Disable the submit button
    }
  }


  const containerStyle = {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    height: "100px", // Adjust the container height as needed
  };


  const sentDataLogin = async () => {
    console.log("ทดสอบยิง");
    if (selectionMail !== "" && selectionPass !== "") {
      const body = {
        email: selectionMail,
        password: selectionPass,
      };
      try {
        await axios
          .post(APIX.signIn, body)
          .then((res) => {
            if (res.status == 200) {
              setAlt(false)
              if (
                res.data.token !== "undefined" &&
                res.data.adminid !== "undefined"
              ) {
                setToken(res.data.token);
                sessionStorage.setItem("Token", res.data.token);
                sessionStorage.setItem("adminid", res.data.adminid);
                navigate("/");
              }
            }
            if(res.status==204){
              setAlt(true)
            }
          })
          .catch((error) => {
            console.log("apiยิงไม่ได้");
          });
      } catch {
        console.log("ยังเข้ารหัสไม่ได้");
      }
    } else {
      console.log("ยังไม่ได้กรอกอะไรเลย");
    }
  };
  return (
    <div>
       <div
      style={{
        display: "flex",
        justifyContent: "Center",
        alignItems: "Center",
        marginTop: "3rem",
        // height: '100vh'
      }}
      className="bg-text rounded"
    >
      <div>
       <AlertDialog open={alt} setOpen={setAlt} mode={"error"} msg={"รหัสผ่านหรือบัญชีผู้ใช้ไม่ถูกต้อง"}></AlertDialog>
        <div>
          <div style={containerStyle}>
            <img src={imgUser} style={imageStyle} class="card-img" />
          </div>

          <h3 style={{ textAlign: "center" }}>ลงชื่อเข้าใช้</h3>
          <div className="mb-3">
            <label>อีเมล</label>
            <input
              value={selectionMail ? selectionMail : ""}
              onChange={(e) => {
                setSelectionMail(e.target.value);
                checkInputs()
              }}
              type="email"
              className="form-control"
              placeholder="กรอกอีเมล"
              // oninput="checkInputs()"
              id='email'
            />
          </div>
          <div className="mb-3">
            <label>รหัสผ่าน</label>
            <input
              value={selectionPass ? selectionPass : ""}
              onChange={(e) => {
                setSelectionPass(e.target.value);
                checkInputs()
              }}
              type="password"
              className="form-control"
              placeholder="กรอกรหัสผ่าน"
              // oninput="checkInputs()"
              id='password'
            />
          </div>
          {/* <div className="mb-3">
						<div className="custom-control custom-checkbox">
							<input
								type="checkbox"
								className="custom-control-input"
								id="customCheck1"
							/>
								<label className="custom-control-label" htmlFor="customCheck1">
								Remember me
								</label>
						</div>
					</div> */}
          <div className="d-grid">
            <p />
            <button
              onClick={() => {
                console.log("กดเข้า");
                sentDataLogin();
                // refreshPage()
              }}
              type="submit"
              className="btn btn-primary"
              style={btnStyle}
              id='submitButton'
            >
              เข้าสู่ระบบ
            </button>
          </div>
          {/* <p style={{ textAlign: "center", marginTop: "1rem" }}>
            <a href="#">ลืมรหัสผ่าน</a>
          </p> */}
          <p style={{ textAlign: "center", marginTop: "1rem" }}>
            <a href="/sign-up">สมัครสมาชิก</a>
          </p>
          <p style={{ textAlign: "center" }}>
            {/* <a href="#">สมัครสมาชิก</a> */}
            การกดเข้าสู่ระบบแสดงว่าคุณได้ยอมรับ ข้อตกลง เงื่อนไข
          </p>
          <p style={{ textAlign: "center" }}>
            {/* <a href="#">สมัครสมาชิก</a> */}
            และนโยบายความเป็นส่วนตัว ของเราเเล้ว
          </p>
        </div>
      </div>
    </div>
    </div>
   
  );
};

export default SignIn;
