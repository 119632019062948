import React, { useState } from "react";
import "./App.css";
import Navbar from "./components/Navbar";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages";
import About from "./pages/about";
import Events from "./pages/events";
import AnnualReport from "./pages/annual";
import Teams from "./pages/team";
import Blogs from "./pages/blogs";
import SignUp from "./pages/signup";
import SignIn from "./pages/signIn";
import TeamsEdit from "./pages/team_edit";
import AddTag from "./pages/addTag";
import AddImg from "./pages/addImg";
import AddPdf from "./pages/addPdf"
import { useEffect } from "react";


function App() {
  const [token, setToken] = useState()

  useEffect(()=>{
    setToken(sessionStorage.getItem("Token"))
  },[])

  return (
    <Router>
      <Navbar token={token} setToken={setToken}/>
      <Routes>
        <Route path="/" element={<Home token={token}/>} />
        <Route path="/about" element={<About />} />
        <Route path="/events" element={<Events token={token}/>} />
        <Route path="/annual" element={<AnnualReport token={token}/>} />
        <Route path="/team" element={<Teams />} />
        <Route path="/teamEdit" element={<TeamsEdit />} />
        <Route path="/addTag" element={<AddTag/>} />
        <Route path="/addImg" element={<AddImg/>} />
        <Route path="/addPdf" element={<AddPdf/>} />
        <Route path="/blogs" element={<Blogs />} />
        <Route path="/sign-up" element={<SignUp />} />
        <Route path="/sign-in" element={<SignIn setToken={setToken} />} />
      </Routes>
    </Router>
  );
}

export default App;
