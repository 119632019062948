import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Multiselect from "multiselect-react-dropdown";
import { APIX } from "../config/config";
import AlertDialog from "../components/Alert/alert";

const AddTag = () => {
  const navigate = useNavigate();
  const [alt, setAlt] = useState(false)
  const [alt2, setAlt2] = useState(false)
  const [imgName, setImgName] = useState("");
  const [file, setFile] = useState();
  const [img, setImg] = useState("");
  const [tagName, setTagName] = useState("");
  const [selectionLink, setSelectionLink] = useState("");
  const [tagDes, setTagDes] = useState("");
  const [selected, setSelected] = useState([]);
  // const [selectionAdmin, setSelectionAdmin] = useState(sessionStorage.getItem("adminid"));
  const [selectionType, setSelectionType] = useState("1001");
  const [option1, setOption1] = useState([]);
  const sentDataCreatVideo = async () => {
    // const body = {
    //   tagName: tagName,
    //   tagDetail: tagDes,
    //   file: file,
    //   // tagid:'4'
    // };
    const formData = new FormData();
    formData.append('tagName', tagName);
    formData.append('tagDetail', tagDes);
    formData.append('file', file);
    try {
      console.log("ข้อมูลที่ส่ง:",formData);
      const response = await axios.post(APIX.tag_create, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setAlt(false)
      console.log('สร้างได้เเล้ว', response.data);
      navigate("/team");
    } catch (error) {
      setAlt(true)
      console.log('apiยิงไม่ได้', error);
      
    }
  };
  useEffect(() => {
    fetchOption();
  }, []);

  const fetchOption = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: APIX.tag_getAll,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setOption1(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  function onSelect(selectedList, selectedItem) {
    setSelected(selectedList);
  }

  function onRemove(selectedList, removedItem) {
    setSelected(selectedList);
  }
  const state = {
    options: option1,
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "Center",
        alignItems: "Center",
        // marginTop: "1rem",
      }}
      className="bg-text rounded"
    >
      <div>
      <AlertDialog open={alt} setOpen={setAlt} mode={"error"} msg={"กรุณากรอกเงื่อนไขให้ครบถ้วน"}></AlertDialog>
      <AlertDialog open={alt2} setOpen={setAlt2} mode={"error"} msg={"ชื่อคลิปหรือรายละเอียดนี้มีอยู่เเล้ว"}></AlertDialog>
        <div>
          <h3 style={{ textAlign: "center" }}>เพิ่ม Tag</h3>
          <div className="mb-3" style={{ marginTop: "2rem" }}></div>
          <div className="mb-3">
            <label>* ชื่อ Tag</label>
            <input
              value={tagName ? tagName : ""}
              onChange={(e) => {
                setTagName(e.target.value);
              }}
              type="name"
              className="form-control"
              placeholder="กรอกชื่อ Tag"
            />
          </div>
          <div className="mb-3">
            <label>* รายละเอียด Tag</label>
            <input
              value={tagDes ? tagDes : ""}
              onChange={(e) => {
                setTagDes(e.target.value);
              }}
              type="detail"
              className="form-control"
              placeholder="กรอกรายละเอียด Tag"
            />
          </div>
          <div className="mb-3">
            <label>* อัปโหลดรูปภาพ</label>
            <input
              value={img ? img : ""}
              onChange={(e) => {
                setImg(e.target.value);
                setFile(e.target.files[0]);
              }}
              type="file"
              accept="image/*" 
              className="form-control"
              placeholder="กรอกรายละเอียด Tag"
            />
          </div>
          
          <div className="d-grid" style={{ marginTop: "1.5rem" }}>
            <div className="d-flex align-items-start  mb-3 ">
              <div style={{ margin: "auto" }}>
                <a href="/team">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    style={{
                      textAlign: "center",
                      padding: "0.7rem",
                      marginRight: "2rem",
                      backgroundColor: "#FF3333",
                      border: "none",
                      width: 90,
                    }}
                  >
                    ยกเลิก
                  </button>
                </a>
                {/* <a href="/" > */}
                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{
                    textAlign: "center",
                    padding: "0.7rem",
                    backgroundColor: "#12168B",
                    border: "none",
                    width: 90,
                  }}
                  onClick={() => {
                    sentDataCreatVideo();
                  }}
                >
                  ตกลง
                </button>

                {/* </a> */}
              </div>
            </div>
            {/* <a href="/" style={{textAlign:'center'}}>
						<button type="submit" className="btn btn-primary"  >
							ตกลง
						</button>
					</a> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddTag;
