import React, {useState} from 'react';
import Grid from '@mui/material/Grid';

const Blogs = () => {
	const [page, setPage] = useState(1)
	return (
		<div>
			<Grid container spacing={2}>
				<Grid xs={4}>
					<div>
						<div className="m-3">
							<div >
								<div class="card text zoom" onClick={()=>{setPage(1)}}>
									<div class="card-img">
										<h3 class="card-title font-pic">PDF </h3>
									</div>
								</div>
								<div class="card text zoom" onClick={()=>{setPage(2)}}>
									<div class="card-img row-1">
										<text class="card-title font-pic">รูป</text>
									</div>
								</div>
								<div class="card text zoom" onClick={()=>{setPage(3)}}>
									<div class="card-img">
										<h3 class="card-title font-pic">วิดีโอ</h3>
									</div>
								</div>
							</div>
						</div>
					</div>
				</Grid>
				<Grid xs={8}>						

				</Grid>
			</Grid>

		</div>

	);
};

export default Blogs;
