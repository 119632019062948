import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Slide } from "react-slideshow-image";
import axios from "axios";
import "./index.css";
import "react-slideshow-image/dist/styles.css";
import { APIX } from "../config/config";
import image1 from "../image/chat.png";
import image2 from "../image/digital-campaign.png";
import image3 from "../image/buttons.png";
import image01 from "../image/diverse-friends-holding-speech-bubbles.jpg";
import image02 from "../image/media.jpg";
import image03 from "../image/customer-experience-creative-collage.jpg";
import { Carousel } from "react-bootstrap";
import ImageBG from "../image/48f5dfe4-e4b0-4de5-99c6-0932383b90f0.jpg";


const Home = ({token}) => {
  useEffect(() => {
    apiImage();
    apiGetNews();
  }, []);
  const imageStyle = {
    width: "150px", // Adjust the width as needed
    height: "120px", // Adjust the height as needed
  };
  const imageStyle2 = {
    width: "230px", // Adjust the width as needed
    height: "320px", // Adjust the height as needed
  };


  useEffect(() => {
    apiImage();
    // window.location.reload();
  }, []);
  const [showImage, setShowImage] = useState([]);
  const navigate = useNavigate();
  const [data, setData] = useState([]);

  const fetchData = (id) => {
    axios.get(APIX.pdf_getId + id)
      .then((response) => {
        handleDownload(response.data[0])
      })
      .catch((error) => {
        console.log(error);
      });
  }

  console.log("ShowImage=========>", showImage);

  const handleDownload = (data) => {
    //อันนี้โหลดเข้าเครื่องเลย
    // if (data) {
    //   const uint8Array = new Uint8Array(data.path.data);
    //   const blob = new Blob([uint8Array], { type: 'application/pdf' });
    //   const url = URL.createObjectURL(blob);
    //   const link = document.createElement('a');
    //   link.href = url;
    //   link.setAttribute('download', data.pdfname);
    //   document.body.appendChild(link);
    //   link.click();
    //   document.body.removeChild(link);
    // }



    //อันนี้เปิดในโครมเลย
    if (data) {
      const uint8Array = new Uint8Array(data.path.data);
      const blob = new Blob([uint8Array], { type: 'application/pdf' });
      const url = URL.createObjectURL(blob);

      // Open the PDF in a new browser tab or window
      window.open(url, '_blank');
    }
  };

  const apiGetNews = async () => {
    try {
      await axios
        .get(APIX.pdf_getAll)
        .then((status) => {
          console.log("ดึงรูปได้เเล้ว", status.data);
          setData(status.data);
        })
        .catch((error) => {
          console.log("apiยิงไม่ได้");
        });
    } catch {
      console.log("ยังสร้างรหัสไม่ได้");
    }
  }
  const sentAddmin = async () => {
    navigate("/about");
  }
  const sentSearchCh = async () => {
    navigate("/events");
  }
  const sentSearchNm = async () => {
    navigate("/annual");
  }
  const apiImage = async () => {
    try {
      await axios
        .get(APIX.image_getAll)
        .then((status) => {
          console.log("ดึงรูปได้เเล้ว", status.data);
          setShowImage(status.data);
        })
        .catch((error) => {
          console.log("apiยิงไม่ได้");
        });
    } catch {
      console.log("ยังสร้างรหัสไม่ได้");
    }
  };

  const deleteApi=(arr)=>{
    
    let config = {
      method: 'delete',
      maxBodyLength: Infinity,
      url: 'http://localhost:7070/api/v1/pdf/'+arr.id,
      headers: { }
    };
    
    axios.request(config)
    .then((response) => {
      if(response.status==200){
        const del = data.filter((m)=>m.id!==arr.id)
        setData(del)
      }
    })
    .catch((error) => {
      console.log(error);
    });
    
  }
  return (
    <>
      <div
        style={{
          display: "flex",
          marginLeft: "3rem"
        }}
      >
        <h1 className="mt-3 mb-2" style={{ fontSize: "2rem" }}>
        </h1>
      </div>
      <div className="mb-5 rounded">
        <Carousel
          className="d-flex align-items-start w-50  shadow rounded"
          style={{ margin: "auto" }}
        >
          {showImage.map((data) => {
            return (
              <Carousel.Item>
                <a href="/" target="_blank" className="hover-zoom">
                  <div className=" hover-zoom">

                    <img
                      className="d-block w-100 rounded"

                      src={`data:image/jpeg;base64,${data.path_base64}`}
                      alt="Preview"
                      style={imageStyle2}
                    />
                  </div>
                  <Carousel.Caption>
                    <h1 style={{ color: "#4d4d4d" }}>{data.imagename}</h1>

                  </Carousel.Caption>
                </a>
              </Carousel.Item>
            )
          })
          }
        </Carousel>

      </div>
      {/* <div className="d-flex align-items-start bg-light mb-3 "> */}
      <div className="d-flex align-items-start  mb-3 ">
        <div style={{ margin: "auto" }}>
          <a href="/about" >
            {/* <button className="button" 
                    onClick={() => {
                      sentSearchTag1();
                      // refreshPage()
                    }}> 
              กระดานสนทนา 
              </button> */}
            <button type="button" class="btn " onClick={() => {
              sentAddmin();
              // refreshPage()
            }}>
              <div class="card text zoom">
                <img src={image1} style={imageStyle} class="card-img m-4" />
                <div class="card-img">
                  <h3 class="card-title font-pic">กระดาน</h3>
                  <h3 class="card-title font-pic">สนทนา</h3>
                </div>
              </div>
            </button>

          </a>
          <a href="/events" >
            {/* <button className="button2"
                    onClick={() => {
                      sentSearchTag2();
                      // refreshPage()
                    }}> 
            สื่อภายในพื้นที่ขุนยวม 
            </button> */}
            <button type="button" class="btn " onClick={() => {
              sentSearchCh();
              // refreshPage()
            }}>
              <div class="card text zoom">
                <img src={image2} style={imageStyle} class="card-img m-4" />
                <div class="card-img row-2">
                  <h3 class="card-title font-pic">สื่อภายใน</h3>
                  <text class="card-title font-pic">พื้นที่ขุนยวม</text>
                </div>
              </div>
            </button>

          </a>
          <a href="/annual" >
            {/* <button className="button2"
                    onClick={() => {
                      sentSearchTag3();
                      // refreshPage()
                    }}> 
            สื่อภายนอกพื้นที่ขุนยวม
             </button> */}
            <button type="button" class="btn " onClick={() => {
              sentSearchNm();
              // refreshPage()
            }}>
              <div class="card text zoom">
                <img src={image3} style={imageStyle} class="card-img m-4" />
                <div class="card-img">
                  <h3 class="card-title font-pic">สื่อภายนอก</h3>
                  <h3 class="card-title font-pic">พื้นที่ขุนยวม</h3>
                </div>
              </div>
            </button>

          </a>
          {token ?
        <a href="/addImg" >
        {/* <button className="button2"
                onClick={() => {
                  sentSearchTag3();
                  // refreshPage()
                }}> 
        สื่อภายนอกพื้นที่ขุนยวม
         </button> */}
        <button type="button" class="btn " onClick={() => {
          // sentSearchNm();
          // refreshPage()
        }}>
          <div class="card text zoom">
            <img src={image3} style={imageStyle} class="card-img m-4" />
            <div class="card-img">
              <h3 class="card-title font-pic">อัปโหลด</h3>
              <h3 class="card-title font-pic">รูปภาพ</h3>
            </div>
          </div>
        </button>

      </a>  
       :<></> }
          
        </div>
      </div>
      <div style={{textAlign:"end",marginRight:"10.5rem"}}>{token ? <a href="/addPdf">อัปโหลด PDF</a> : <></>}</div>
      <div style={{
        width: "100%",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}>
        
        <table style={{ border: "2px solid forestgreen", width: "800px", height: "200px" }}>
          <tr>
            <th style={{ borderBottom: "1px solid black" }}>ข่าวประชาสัมพันธ์</th>
            <th style={{ borderBottom: "1px solid black" }}>โพสเมื่อ</th>
            <th style={{ borderBottom: "1px solid black", textAlign: "center" }}>อ่านเพิ่มเติม</th>
          </tr>
          {data.map((val, key) => {
            return (
              <tr key={key}>
                <td style={{ maxWidth: "15rem" }}><a target="_blank"
                  rel="noreferrer" onClick={() => { console.log(val.pdfname); }}>{val.pdfname}</a></td>
                <td >{val.createdate}</td>
                <td><button onClick={() => { fetchData(val.id) }}>เปิด</button></td>
                {token?<td><button onClick={() => { deleteApi(val) }}>ลบ</button></td>:<></>}
              </tr>
            )
          })}
        </table>
      </div>
    </>
  );
};

export default Home;
