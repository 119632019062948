import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Multiselect from "multiselect-react-dropdown";
import { APIX } from "../config/config";
import AlertDialog from "../components/Alert/alert";

const AddImg = () => {
  const navigate = useNavigate();
  const [alt, setAlt] = useState(false)
  const [alt2, setAlt2] = useState(false)
  const [imgName, setImgName] = useState("");
  const [imagelink, setImagelink] = useState("");
  const [imageDesc, setImageDesc] = useState("");
  const [file, setFile] = useState();
  const [selectionLink, setSelectionLink] = useState("");
  const [img, setImg] = useState("");
  const [selected, setSelected] = useState([]);
  const [selectionType, setSelectionType] = useState("1001");
  const [option1, setOption1] = useState([]);
 
 
  const sentDataCreateImg = async () => {
    const formData = new FormData();
    formData.append('imageName', imgName);
    formData.append('imagelink', imagelink);
    formData.append('imageDesc', imageDesc);
    formData.append('image', file);
    formData.append('adminId', sessionStorage.getItem("adminid"));

    try {
      const response = await axios.post(APIX.image_create, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
      setAlt(false)
      if(response.status=='200'){
        navigate('/')
      }
      console.log('สร้างได้เเล้ว', response.data);
    } catch (error) {
      setAlt(true)
      console.log('apiยิงไม่ได้', error);
      
    }
  };



  useEffect(() => {
    fetchOption();
  }, []);

  const fetchOption = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: APIX.tag_getAll,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setOption1(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
  function onSelect(selectedList, selectedItem) {
    setSelected(selectedList);
  }

  function onRemove(selectedList, removedItem) {
    setSelected(selectedList);
  }

  const state = {
    options: option1,
  };

  return (
    <div
      style={{
        display: "flex",
        justifyContent: "Center",
        alignItems: "Center",
        marginTop: "3rem",
      }}
    >
      <div>
      <AlertDialog open={alt} setOpen={setAlt} mode={"error"} msg={"กรุณากรอกเงื่อนไขให้ครบถ้วน"}></AlertDialog>
      <AlertDialog open={alt2} setOpen={setAlt2} mode={"error"} msg={"ชื่อคลิปหรือรายละเอียดนี้มีอยู่เเล้ว"}></AlertDialog>
        <div>
          <h3 style={{ textAlign: "center" }}>
            เพิ่มรูปภาพ
          </h3>
          <div className="mb-3" style={{ marginTop: "2rem" }}></div>
          <div className="mb-3">
            <label>* ชื่อรูปภาพ</label>
            <input
              value={imgName ? imgName : ""}
              onChange={(e) => {
                setImgName(e.target.value);
              }}
              type="name"
              className="form-control"
              placeholder="กรอกชื่อรูปภาพ"
            />
          </div>

          <div className="mb-3">
            <label>* คำอธิบาย</label>
            <input
              value={imageDesc ? imageDesc : ""}
              onChange={(e) => {
                setImageDesc(e.target.value);
              }}
              type="name"
              className="form-control"
              placeholder="คำอธิบาย"
            />
          </div>

          <div className="mb-3">
            <label>* อัปโหลดรูปภาพ</label>
            <input
              value={img ? img : ""}
              onChange={(e) => {
                setImg(e.target.value);
                setFile(e.target.files[0]);
              }}
              type="file"
              accept="image/*" 
              className="form-control"
              placeholder="กรอกรายละเอียด Tag"
            />
          </div>
          
          <div className="d-grid" style={{ marginTop: "1.5rem" }}>
            <div className="d-flex align-items-start  mb-3 ">
              <div style={{ margin: "auto" }}>
                <a href="/">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    style={{
                      textAlign: "center",
                      padding: "0.7rem",
                      marginRight: "2rem",
                    }}
                  >
                    ยกเลิก
                  </button>
                </a>
                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{ textAlign: "center", padding: "0.7rem" }}
                  onClick={() => {
                    sentDataCreateImg();
                  }}
                >
                  ตกลง
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddImg;
