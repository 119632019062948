import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Multiselect from "multiselect-react-dropdown";
import { APIX } from "../config/config";

const TeamsEdit = () => {
  const navigate = useNavigate();

  const setdata = sessionStorage.getItem("videotask");
  const data = JSON.parse(setdata);
  const [selectionName, setSelectionName] = useState(data.videoname);
  const [selectionLink, setSelectionLink] = useState(data.videolink);
  const [selectionDes, setSelectionDes] = useState(data.videodesc);
  const [selected, setSelected] = useState([]);
  // const [selectionAdmin, setSelectionAdmin] = useState(sessionStorage.getItem("adminid"));
  const [selectionType, setSelectionType] = useState(data.typeid);
  const [option1, setOption1] = useState([]);

  useEffect(() => {
    const option = [];
    data.videoDetail.map((e) => {
      return option.push({ id: e.tagid, name: e.tagname });
    });
    setSelected(option);
  }, []);

  const sentDataCreatVideo = async () => {
    const list = [];
    selected.map((e) => {
      list.push({ tagId: e.id });
    });
    const body = {
      videoname: selectionName,
      videolink: selectionLink,
      videodesc: selectionDes,
      adminId: sessionStorage.getItem("adminid"),
      typeId: selectionType,
      listDetail: list,
    };
    try {
      await axios
        .put(APIX.video + data.videoid, body)
        .then((status) => {
          console.log("สร้างได้เเล้ว", status);

          navigate("/");
        })
        .catch((error) => {
          console.log("apiยิงไม่ได้");
        });
    } catch {
      console.log("ยังสร้างรหัสไม่ได้");
    }
  };
  useEffect(() => {
    fetchOption();
  }, []);

  const fetchOption = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: APIX.tag_getAll,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        setOption1(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };

  function onSelect(selectedList, selectedItem) {
    setSelected(selectedList);
    console.log(selectedList);
  }

  function onRemove(selectedList, removedItem) {
    setSelected(selectedList);
  }
  const state = {
    options: option1,
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "Center",
        alignItems: "Center",
        marginTop: "3rem",
      }}
    >
      <div>
        <div>
          <h3 style={{ textAlign: "center" }}>แก้ไขสื่อนวัตกรรมของชาวขุนยวน</h3>
          <div className="mb-3" style={{ marginTop: "2rem" }}></div>
          <div className="mb-3">
            <label>* ชื่อคลิป</label>
            <input
              value={selectionName ? selectionName : ""}
              onChange={(e) => {
                setSelectionName(e.target.value);
              }}
              type="name"
              className="form-control"
              placeholder="กรอกชื่อคลิป"
            />
          </div>
          <div className="mb-3">
            <label>* รายละเอียดคลิป</label>
            <input
              value={selectionDes ? selectionDes : ""}
              onChange={(e) => {
                setSelectionDes(e.target.value);
              }}
              type="detail"
              className="form-control"
              placeholder="กรอกชื่อคลิป"
            />
          </div>
          <div className="mb-3">
            <label>* ประเภทสื่อ</label>
            <select
              className="form-control"
              value={selectionType}
              onChange={(e) => {
                setSelectionType(e.target.value);
              }}
            >
              <option value="1001">สื่อนวัตกรรมของชาวขุนยวน</option>
              <option value="2001">สื่อนวัตกรรมที่น่าสนใจในพื้นที่</option>
            </select>
          </div>
          <div className="mb-3">
            <label>* ลิ้งค์</label>
            <input
              value={selectionLink ? selectionLink : ""}
              onChange={(e) => {
                setSelectionLink(e.target.value);
              }}
              type="name"
              className="form-control"
              placeholder="กรอกลิ้งค์"
            />
          </div>

          <div>
            <label>* แท็ก</label>
            <Multiselect
              options={state.options} // Options to display in the dropdown
              selectedValues={selected} // Preselected value to persist in dropdown
              onSelect={onSelect} // Function will trigger on select event
              onRemove={onRemove} // Function will trigger on remove event
              displayValue="name" // Property name to display in the dropdown options
            />
          </div>
          <div className="d-grid" style={{ marginTop: "1.5rem" }}>
            <div className="d-flex align-items-start  mb-3 ">
              <div style={{ margin: "auto" }}>
                <a href="/">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    style={{
                      textAlign: "center",
                      padding: "1rem",
                      marginRight: "2rem",
                    }}
                  >
                    ยกเลิก
                  </button>
                </a>
                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{ textAlign: "center", padding: "1rem" }}
                  onClick={() => {
                    sentDataCreatVideo();
                  }}
                >
                  ตกลง
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default TeamsEdit;
