import Alert from '@mui/material/Alert';
import Collapse from '@mui/material/Collapse';


const AlertDialog =({open, setOpen, mode, msg})=>{
        return   <Collapse in={open}>
         <Alert variant="outlined" severity={mode}>{msg}</Alert>
      </Collapse> 
}



export default AlertDialog;