import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import { APIX } from "../config/config";
import AlertDialog from "../components/Alert/alert";

const AddPdf = () => {
  const navigate = useNavigate();
  const [alt, setAlt] = useState(false)
  const [alt2, setAlt2] = useState(false)
  const [pdfName, setPdfName] = useState("");
  const [file, setFile] = useState("");
  const [selected, setSelected] = useState([]);
  const [option1, setOption1] = useState([]);
  const [fileName, setFileName] = useState()
  


  const sentDataCreatePdf = async () => {
    const formData = new FormData();
    formData.append('pdfName', pdfName);
    formData.append('file', file);
    formData.append('adminId', sessionStorage.getItem("adminid"));


    try {
      const response = await axios.post(APIX.pdf_create, formData, {
        headers: {
          'Content-Type': 'multipart/form-data', 
        },
      });
if(response.status=='200'){
  navigate('/')
}
      console.log('สร้างได้เเล้ว', response.data);
      setAlt(false)
    } catch (error) {
      setAlt(true)
      console.log('apiยิงไม่ได้', error);
      
    }
  };

  useEffect(() => {
    fetchOption();
  }, []);

  const fetchOption = () => {
    let config = {
      method: "get",
      maxBodyLength: Infinity,
      url: APIX.tag_getAll,
      headers: {},
    };

    axios
      .request(config)
      .then((response) => {
        console.log(JSON.stringify(response.data));
        setOption1(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
  };
  function onSelect(selectedList, selectedItem) {
    setSelected(selectedList);
  }

  function onRemove(selectedList, removedItem) {
    setSelected(selectedList);
  }
  const state = {
    options: option1,
  };
  return (
    <div
      style={{
        display: "flex",
        justifyContent: "Center",
        alignItems: "Center",
        marginTop: "3rem",
      }}
    >
      <div>
      <AlertDialog open={alt} setOpen={setAlt} mode={"error"} msg={"กรุณากรอกเงื่อนไขให้ครบถ้วน"}></AlertDialog>
      <AlertDialog open={alt2} setOpen={setAlt2} mode={"error"} msg={"ชื่อคลิปหรือรายละเอียดนี้มีอยู่เเล้ว"}></AlertDialog>
        <div>
          <h3 style={{ textAlign: "center" }}>
            เพิ่มไฟล์ประชาสัมพันธ์
          </h3>
          <div className="mb-3" style={{ marginTop: "2rem" }}></div>
          <div className="mb-3">
            <label>* ชื่อไฟล์</label>
            <input
              value={pdfName ? pdfName : ""}
              onChange={(e) => {
                setPdfName(e.target.value);
              }}
              type="name"
              className="form-control"
              placeholder="กรอกชื่อไฟล์"
            />
          </div>
          <div className="mb-3">
            <label>* อัปโหลดไฟล์(PDF)</label>
            <input
            
              value={fileName ? fileName : ""}
              onChange={(e) => {
                setFileName(e.target.value);
                setFile(e.target.files[0])
              }}
              type="file"
              className="form-control"
              placeholder="อัปโหลดไฟล์(PDF)"
              accept=".pdf"
            />
          </div>
          
          <div className="d-grid" style={{ marginTop: "1.5rem" }}>
            <div className="d-flex align-items-start  mb-3 ">
              <div style={{ margin: "auto" }}>
                <a href="/">
                  <button
                    type="submit"
                    className="btn btn-primary"
                    style={{
                      textAlign: "center",
                      padding: "0.7rem",
                      marginRight: "2rem",
                    }}
                  >
                    ยกเลิก
                  </button>
                </a>
                {/* <a href="/" > */}
                <button
                  type="submit"
                  className="btn btn-primary"
                  style={{ textAlign: "center", padding: "0.7rem" }}
                  onClick={() => {
                    sentDataCreatePdf();
                  }}
                >
                  ตกลง
                </button>
                
                {/* </a> */}
              </div>
            </div>
            {/* <a href="/" style={{textAlign:'center'}}>
						<button type="submit" className="btn btn-primary"  >
							ตกลง
						</button>
					</a> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AddPdf;
